import { Empresa } from './Empresa.model';
import { Moneda } from './Moneda.model';

export class DocumentoPagoCuenta {
    public id: number;
    public empresa: Empresa;
    public moneda: Moneda;
    public codigo: number;
    public nombre: string;
    public saldo: number;

    constructor() {
        this.id = 0;
        this.empresa = new Empresa();
        this.moneda = new Moneda();
        this.codigo = 0;
        this.nombre = '';
        this.saldo = 0;
    }
}
