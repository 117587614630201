import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { backend_url } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class SoporteService {
    constructor(private http: HttpClient) {}

    /* Soporte > Servicios */
    getSoporteServicioData() {
        return this.http.get(
            `${backend_url}soporte/garantia-devolucion/servicio/data`
        );
    }

    saveSoporteServicioDocument(data: object) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(data));

        return this.http.post(
            `${backend_url}soporte/garantia-devolucion/servicio/crear`,
            form_data
        );
    }

    getSoporteServicioPendienteRevisionData() {
        return this.http.get(
            `${backend_url}soporte/garantia-devolucion/servicio/revision/data`
        );
    }

    saveSoporteServicioPendienteRevisionDocument(data: object) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(data));

        return this.http.post(
            `${backend_url}soporte/garantia-devolucion/servicio/revision/guardar`,
            form_data
        );
    }

    getSoporteServicioPendienteCotizacionData() {
        return this.http.get(
            `${backend_url}soporte/garantia-devolucion/servicio/cotizacion/data`
        );
    }

    createSoporteServicioPendienteCotizacionProductPrice(data: object) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(data));

        return this.http.post(
            `${backend_url}soporte/garantia-devolucion/servicio/cotizacion/crear`,
            form_data
        );
    }

    saveSoporteServicioPendienteCotizacionDocument(data: object) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(data));

        return this.http.post(
            `${backend_url}soporte/garantia-devolucion/servicio/cotizacion/guardar`,
            form_data
        );
    }

    getSoporteServicioPendienteReparacionData() {
        return this.http.get(
            `${backend_url}soporte/garantia-devolucion/servicio/reparacion/data`
        );
    }

    saveSoporteServicioPendienteReparacionDocument(data: object) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(data));

        return this.http.post(
            `${backend_url}soporte/garantia-devolucion/servicio/reparacion/guardar`,
            form_data
        );
    }

    getSoporteServicioPendientePedidoData() {
        return this.http.get(
            `${backend_url}soporte/garantia-devolucion/servicio/pedido/data`
        );
    }

    saveSoporteServicioPendientePedidoDocument(data: object) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(data));

        return this.http.post(
            `${backend_url}soporte/garantia-devolucion/servicio/pedido/guardar`,
            form_data
        );
    }

    getSoporteServicioPendienteEnvioData() {
        return this.http.get(
            `${backend_url}soporte/garantia-devolucion/servicio/envio/data`
        );
    }

    saveSoporteServicioPendienteEnvioDocument(data: object) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(data));

        return this.http.post(
            `${backend_url}soporte/garantia-devolucion/servicio/envio/guardar`,
            form_data
        );
    }

    getSoporteServicioHistorialData(data: object) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(data));

        return this.http.post(
            `${backend_url}soporte/garantia-devolucion/servicio/historial/data`,
            form_data
        );
    }

    saveSoporteServicioPendienteHistorialDocument(data: object) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(data));

        return this.http.post(
            `${backend_url}soporte/garantia-devolucion/servicio/historial/guardar`,
            form_data
        );
    }

    /* Soporte > Garantia */
    getGarantiaHistorialData(data: object) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(data));

        return this.http.post(
            `${backend_url}soporte/garantia-devolucion/garantia/historial/data`,
            form_data
        );
    }

    /* Soporte > Devolucion */

    getDevolucionHistorialData(data: object) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(data));

        return this.http.post(
            `${backend_url}soporte/garantia-devolucion/devolucion/historial/data`,
            form_data
        );
    }

    deleteGarantiaDevolucionDocument(document: any) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(document));

        return this.http.post(
            `${backend_url}soporte/garantia-devolucion/eliminar`,
            form_data
        );
    }

    getSoporteGarantiaDevolucionServicioDocumentPDF(documento: number) {
        return this.http.get(
            `${backend_url}soporte/garantia-devolucion/documento/${documento}`
        );
    }
}
