import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { backend_url } from '@env/environment';
import { MarketplaceArea } from '@models/MarketplaceArea.model';
import { Usuario } from '@models/Usuario.model';

@Injectable({
    providedIn: 'root',
})
export class ConfiguracionService {
    constructor(private http: HttpClient) {}

    /* Configuracion > Usuario > Gestion */

    getUsuarioGestionData() {
        return this.http.get(
            `${backend_url}configuracion/usuario/gestion/data`
        );
    }

    disableUserUsuarioGestion(usuario_id: number) {
        return this.http.get(
            `${backend_url}configuracion/usuario/gestion/desactivar/${usuario_id}`
        );
    }

    registerUserUsuarioGestion(usuario: Usuario) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(usuario));

        return this.http.post(
            `${backend_url}configuracion/usuario/gestion/registrar`,
            form_data
        );
    }

    /* Configuracion > Sistema > Marketplaces */

    getConfiguracionSistemaMarketplaceData() {
        return this.http.get(
            `${backend_url}configuracion/sistema/marketplace/data`
        );
    }

    getAccessToViewApiData(data: object) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(data));

        return this.http.post(
            `${backend_url}configuracion/sistema/marketplace/ver-credenciales`,
            form_data
        );
    }

    saveConfiguracionSistemaMarketplace(data: MarketplaceArea) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(data));

        return this.http.post(
            `${backend_url}configuracion/sistema/marketplace/guardar`,
            form_data
        );
    }
}
