import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { backend_url, backend_url_erp } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class ContabilidadService {
    constructor(private http: HttpClient) {}

    /* Contabildiad > Flujo */
    changeMovementClient(data: object) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(data));

        return this.http.post(
            `${backend_url}contabilidad/ingreso/editar/cliente`,
            form_data
        );
    }

    /* Contabilidad > Pagos */
    getContabilidadPagosData() {
        return this.http.get(`${backend_url}contabilidad/pagos/data`);
    }

    getContabilidadPagosCuentasByCompany(data: object) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(data));

        return this.http.post(
            `${backend_url}contabilidad/pagos/cuentas`,
            form_data
        );
    }

    saveContabilidadPagosDocument(data: object) {
        const form_data = new FormData();
        form_data.append('data', JSON.stringify(data));

        return this.http.post(
            `${backend_url}contabilidad/pagos/guardar`,
            form_data
        );
    }
}
