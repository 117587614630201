export enum DocumentoTipo {
    ORDEN_DE_COMPRA = 0,
    COMPRA = 1,
    VENTA = 2,
    ENTRADA = 3,
    SALIDA = 4,
    TRASPASO = 5,
    NOTA_CREDITO = 6,
    PRETRANSFERENCIA = 9,
    USO_INTERNO = 11,
    REQUISICION_ORDEN_COMPRA = 12,
}
